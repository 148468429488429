import React, { useEffect } from 'react';
import StatusWidget from '../../StatusWidget/StatusWidget';

const Week15OpponentsModal = ({
	player,
	selectedPlayers = [],
	isPlayerWeek15OpponentsModalOpen,
	closeWeek15OpponentsModal,
	modalRef
}) => {
	const isPlayerDrafted = (opponentPlayer) => {
		return selectedPlayers.some(
			(selectedPlayer) =>
				selectedPlayer.first_name === opponentPlayer.first_name &&
				selectedPlayer.last_name === opponentPlayer.last_name
		);
	};

	useEffect(
		() => {
			if (isPlayerWeek15OpponentsModalOpen) {
				document.body.style.overflow = 'hidden';
			}

			return () => {
				// Cleanup on modal close or component unmount
				document.body.style.overflow = '';
			};
		},
		[
			isPlayerWeek15OpponentsModalOpen
		]
	);

	return (
		<div
			className="fixed inset-0 flex justify-center items-center"
			style={{
				zIndex: 1000,
				backgroundColor: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div
				className="relative bg-white rounded-lg p-8 shadow-xl"
				style={{
					maxHeight: '90vh',
					overflowY: 'auto'
				}}
				ref={modalRef}
			>
				<button
					className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200 transition duration-300"
					onClick={closeWeek15OpponentsModal}
				>
					<svg
						className="h-6 w-6 text-gray-600"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				<h2 className="text-2xl font-bold mb-6 w-full pr-12">
					{player && player.first_name ? (
						`${player.first_name}'s Week 15 Opponents`
					) : (
						'Week 15 Opponents'
					)}
				</h2>
				<div className="overflow-x-auto">
					{player &&
					player.week_15_opponent &&
					player.week_15_opponent.opponent_players ? (
						<div className="flex flex-col">
							{[
								'quarterback',
								'running back',
								'wide receiver',
								'tight end'
							].map((position) => (
								<div key={position}>
									<h2 className="text-xl font-bold my-3">
										{position.toUpperCase()}S
									</h2>
									{player.week_15_opponent.opponent_players
										.filter(
											(opponent_player) => opponent_player.position === position
										)
										.map((opponent_player) => {
											const drafted = isPlayerDrafted(opponent_player);

											return (
												<div
													key={opponent_player.id}
													className={`relative flex flex-col lg:flex-row justify-between items-center mb-2 px-3 py-2 rounded-xl my-3 ${drafted
														? ''
														: 'shadow-sm border border-gray-300'} bg-white w-full`}
												>
													{drafted && (
														<div className="absolute inset-0 bg-black opacity-20 rounded-xl z-30" />
													)}
													<div className="flex items-center w-full">
														<div className="relative mr-4">
															<img
																src={
																	opponent_player.image_url ? (
																		`${opponent_player.image_url}?w=50&h=50`
																	) : (
																		'default_image_url'
																	)
																}
																alt={
																	opponent_player.first_name &&
																	opponent_player.last_name ? (
																		`${opponent_player.first_name} ${opponent_player.last_name}`
																	) : (
																		'Player Image'
																	)
																}
																style={{
																	width: '50px',
																	height: '50px',
																	objectFit: 'cover',
																	border: '1px solid #ddd',
																	borderRadius: '50%',
																	backgroundColor: 'white'
																}}
															/>
															<div className="absolute top-2 left-9 transform translate-x-1/2 -translate-y-1/2">
																<StatusWidget
																	status={opponent_player.status || 'Unknown'}
																	description={
																		opponent_player.injury_description ||
																		'No description'
																	}
																	hideLabel={true}
																	is50pXThumbnail={true}
																/>
															</div>
														</div>
														<div className="flex flex-col justify-center text-center">
															<div>
																{opponent_player.first_name &&
																opponent_player.last_name ? (
																	`${opponent_player.first_name} ${opponent_player.last_name}`
																) : (
																	'Player Name'
																)}
															</div>
														</div>
													</div>
												</div>
											);
										})}
								</div>
							))}
						</div>
					) : (
						<div className="text-center text-gray-500">
							No opponents available
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Week15OpponentsModal;
