getRosterPlatformUrl; // utils/helpers.js

export const getRosterPlatformUrl = (platform, rosterUrl) => {
	const platformUrls = {
		ESPN: rosterUrl || 'https://www.espn.com/fantasy/football/',
		FFPC: rosterUrl || 'https://myffpc.com/account/Login.aspx',
		NFL: rosterUrl || 'https://www.nfl.com/fantasy',
		Yahoo: rosterUrl || 'https://football.fantasysports.yahoo.com/',
		Underdog: rosterUrl || 'https://underdogfantasy.com/lobby',
		Sleeper: rosterUrl || 'https://sleeper.com/login'
	};

	return platformUrls[platform] || null;
};
