import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Context
import { AuthProvider } from '../../contexts/AuthContext';

// Layouts
import Header from '../Layouts/Header';
import PageContent from '../Layouts/PageContent';
import Footer from '../Layouts/Footer';

// Pages
import SignInPage from '../Pages/Pages/SignInPage';
import SignUpPage from '../Pages/Pages/SignUpPage';
import PasswordResetRequestPage from '../Pages/Pages/PasswordResetRequestPage';
import PasswordResetNewPasswordPage from '../Pages/Pages/PasswordResetNewPasswordPage';
import ProspectsIndexPage from '../Pages/Pages/ProspectsIndexPage';
import ProspectProfilePage from '../Pages/Pages/ProspectProfilePage';
import PlayersIndexPage from '../Pages/Pages/PlayersIndexPage';
import PlayerProfilePage from '../Pages/Pages/PlayerProfilePage';
import TeamsIndexPage from '../Pages/Pages/TeamsIndexPage';
import TeamProfilePage from '../Pages/Pages/TeamProfilePage';
import RostersIndexPage from '../Pages/Pages/RostersIndexPage';
import RosterProfilePage from '../Pages/Pages/RosterProfilePage';
import CoursesIndexPage from '../Pages/Pages/CoursesIndexPage';
import CourseProfilePage from '../Pages/Pages/CourseProfilePage';
import MarketingPage from './Pages/MarketingPage';
import PricingPage from './Pages/PricingPage';
import UserProfilePage from './Pages/UserProfilePage';
import HelpCenterIndexPage from './Pages/HelpCenterIndexPage';
import ArticlePage from './Pages/ArticlePage';
import DynamicFFLogoRoute from '../DynamicFFLogoRoute/DynamicFFLogoRoute';

// PrivateRoute
import PrivateRoute from '../PrivateRoute/PrivateRoute';

export default function App({
	endpoints,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	return (
		<Router>
			<AuthProvider endpoints={endpoints}>
				<Header
					get_players_endpoint={endpoints.get_players_endpoint}
					sign_out_endpoint={endpoints.sign_out_endpoint}
				/>
				<PageContent>
					<Routes>
						<Route
							path="/signin"
							element={
								<SignInPage sign_in_endpoint={endpoints.sign_in_endpoint} />
							}
						/>
						<Route
							path="/signup"
							element={
								<SignUpPage
									create_user_endpoint={endpoints.create_user_endpoint}
								/>
							}
						/>
						<Route
							path="/password-reset"
							element={
								<PasswordResetRequestPage
									user_password_create_endpoint={
										endpoints.user_password_create_endpoint
									}
								/>
							}
						/>
						<Route
							path="/password-reset/update"
							element={
								<PasswordResetNewPasswordPage
									user_password_update_endpoint={
										endpoints.user_password_update_endpoint
									}
								/>
							}
						/>
						<Route
							path="/pricing"
							element={
								<PricingPage
									create_checkout_session_endpoint={
										endpoints.create_checkout_session_endpoint
									}
									stripe_public_key={stripe_public_key}
									stripe_product_essential_id={stripe_product_essential_id}
									stripe_product_advanced_id={stripe_product_advanced_id}
									stripe_product_ultimate_id={stripe_product_ultimate_id}
								/>
							}
						/>
						<Route
							path="/"
							element={
								<DynamicFFLogoRoute>
									<MarketingPage
										create_checkout_session_endpoint={
											endpoints.create_checkout_session_endpoint
										}
										stripe_public_key={stripe_public_key}
										stripe_product_essential_id={stripe_product_essential_id}
										stripe_product_advanced_id={stripe_product_advanced_id}
										stripe_product_ultimate_id={stripe_product_ultimate_id}
									/>
								</DynamicFFLogoRoute>
							}
						/>
						<Route
							path="/players"
							element={
								<PlayersIndexPage
									get_player_endpoint={endpoints.get_player_endpoint}
									get_players_endpoint={endpoints.get_players_endpoint}
									get_team_endpoint={endpoints.get_team_endpoint}
									get_roster_by_user_endpoint={
										endpoints.get_roster_by_user_endpoint
									}
									create_roster_by_user_endpoint={
										endpoints.create_roster_by_user_endpoint
									}
									update_roster_by_user_endpoint={
										endpoints.update_roster_by_user_endpoint
									}
									create_roster_player_by_roster_endpoint={
										endpoints.create_roster_player_by_roster_endpoint
									}
									delete_roster_player_by_roster_endpoint={
										endpoints.delete_roster_player_by_roster_endpoint
									}
									get_roster_player_note_endpoint={
										endpoints.get_roster_player_note_endpoint
									}
									create_roster_player_note_endpoint={
										endpoints.create_roster_player_note_endpoint
									}
									update_roster_player_note_endpoint={
										endpoints.update_roster_player_note_endpoint
									}
									get_notes_by_roster_endpoint={
										endpoints.get_notes_by_roster_endpoint
									}
									update_notes_by_roster_endpoint={
										endpoints.update_notes_by_roster_endpoint
									}
									get_draft_notes_by_roster_endpoint={
										endpoints.get_draft_notes_by_roster_endpoint
									}
									update_draft_notes_by_roster_endpoint={
										endpoints.update_draft_notes_by_roster_endpoint
									}
									get_lessons_learned_notes_by_roster_endpoint={
										endpoints.get_lessons_learned_notes_by_roster_endpoint
									}
									update_lessons_learned_notes_by_roster_endpoint={
										endpoints.update_lessons_learned_notes_by_roster_endpoint
									}
									create_checkout_session_endpoint={
										endpoints.create_checkout_session_endpoint
									}
									stripe_public_key={stripe_public_key}
									stripe_product_essential_id={stripe_product_essential_id}
									stripe_product_advanced_id={stripe_product_advanced_id}
									stripe_product_ultimate_id={stripe_product_ultimate_id}
								/>
							}
						/>
						<Route
							path="/players/:id"
							element={
								<PlayerProfilePage
									get_player_endpoint={endpoints.get_player_endpoint}
									get_players_endpoint={endpoints.get_players_endpoint}
									create_checkout_session_endpoint={
										endpoints.create_checkout_session_endpoint
									}
									stripe_public_key={stripe_public_key}
									stripe_product_essential_id={stripe_product_essential_id}
									stripe_product_advanced_id={stripe_product_advanced_id}
									stripe_product_ultimate_id={stripe_product_ultimate_id}
								/>
							}
						/>
						<Route
							path="/teams"
							element={
								<TeamsIndexPage
									get_teams_search_by_coach_endpoint={
										endpoints.get_teams_search_by_coach_endpoint
									}
								/>
							}
						/>
						<Route
							path="/teams/:id"
							element={
								<TeamProfilePage
									get_team_endpoint={endpoints.get_team_endpoint}
									get_teams_endpoint={endpoints.get_teams_endpoint}
									create_checkout_session_endpoint={
										endpoints.create_checkout_session_endpoint
									}
									stripe_public_key={stripe_public_key}
									stripe_product_essential_id={stripe_product_essential_id}
									stripe_product_advanced_id={stripe_product_advanced_id}
									stripe_product_ultimate_id={stripe_product_ultimate_id}
								/>
							}
						/>
						<Route
							path="/prospects"
							element={
								<ProspectsIndexPage
									get_players_endpoint={endpoints.get_players_endpoint}
									get_prospects_endpoint={endpoints.get_prospects_endpoint}
								/>
							}
						/>
						<Route
							path="/prospects/:id"
							element={
								<ProspectProfilePage
									get_prospect_endpoint={endpoints.get_prospect_endpoint}
									get_prospects_endpoint={endpoints.get_prospects_endpoint}
									get_player_endpoint={endpoints.get_player_endpoint}
									get_players_endpoint={endpoints.get_players_endpoint}
									create_checkout_session_endpoint={
										endpoints.create_checkout_session_endpoint
									}
									stripe_public_key={stripe_public_key}
									stripe_product_essential_id={stripe_product_essential_id}
									stripe_product_advanced_id={stripe_product_advanced_id}
									stripe_product_ultimate_id={stripe_product_ultimate_id}
								/>
							}
						/>
						<Route
							path="/help-center"
							element={
								<HelpCenterIndexPage
									get_articles_endpoint={endpoints.get_articles_endpoint}
								/>
							}
						/>
						<Route
							path="/help-center/:id"
							element={
								<ArticlePage
									get_article_endpoint={endpoints.get_article_endpoint}
								/>
							}
						/>

						{/* User Specific Routes */}
						<Route
							path="/my-profile"
							element={
								<PrivateRoute>
									<UserProfilePage
										get_user_endpoint={endpoints.get_user_endpoint}
										update_user_endpoint={endpoints.update_user_endpoint}
										get_subscriptions_by_user_endpoint={
											endpoints.get_subscriptions_by_user_endpoint
										}
										create_checkout_session_endpoint={
											endpoints.create_checkout_session_endpoint
										}
										stripe_public_key={stripe_public_key}
										stripe_product_essential_id={stripe_product_essential_id}
										stripe_product_advanced_id={stripe_product_advanced_id}
										stripe_product_ultimate_id={stripe_product_ultimate_id}
										create_billing_portal_session_endpoint={
											endpoints.create_billing_portal_session_endpoint
										}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path="/rosters"
							element={
								<PrivateRoute>
									<RostersIndexPage
										get_rosters_by_user_endpoint={
											endpoints.get_rosters_by_user_endpoint
										}
										get_roster_by_user_endpoint={
											endpoints.get_roster_by_user_endpoint
										}
										create_roster_by_user_endpoint={
											endpoints.create_roster_by_user_endpoint
										}
										update_roster_by_user_endpoint={
											endpoints.update_roster_by_user_endpoint
										}
										delete_roster_by_user_endpoint={
											endpoints.delete_roster_by_user_endpoint
										}
										create_checkout_session_endpoint={
											endpoints.create_checkout_session_endpoint
										}
										stripe_public_key={stripe_public_key}
										stripe_product_essential_id={stripe_product_essential_id}
										stripe_product_advanced_id={stripe_product_advanced_id}
										stripe_product_ultimate_id={stripe_product_ultimate_id}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path="/rosters/:id"
							element={
								<PrivateRoute>
									<RosterProfilePage
										get_roster_by_user_endpoint={
											endpoints.get_roster_by_user_endpoint
										}
										get_player_endpoint={endpoints.get_player_endpoint}
										get_players_endpoint={endpoints.get_players_endpoint}
										get_team_endpoint={endpoints.get_team_endpoint}
										update_roster_by_user_endpoint={
											endpoints.update_roster_by_user_endpoint
										}
										create_roster_player_by_roster_endpoint={
											endpoints.create_roster_player_by_roster_endpoint
										}
										delete_roster_player_by_roster_endpoint={
											endpoints.delete_roster_player_by_roster_endpoint
										}
										get_roster_player_note_endpoint={
											endpoints.get_roster_player_note_endpoint
										}
										create_roster_player_note_endpoint={
											endpoints.create_roster_player_note_endpoint
										}
										update_roster_player_note_endpoint={
											endpoints.update_roster_player_note_endpoint
										}
										get_notes_by_roster_endpoint={
											endpoints.get_notes_by_roster_endpoint
										}
										update_notes_by_roster_endpoint={
											endpoints.update_notes_by_roster_endpoint
										}
										get_draft_notes_by_roster_endpoint={
											endpoints.get_draft_notes_by_roster_endpoint
										}
										update_draft_notes_by_roster_endpoint={
											endpoints.update_draft_notes_by_roster_endpoint
										}
										get_lessons_learned_notes_by_roster_endpoint={
											endpoints.get_lessons_learned_notes_by_roster_endpoint
										}
										update_lessons_learned_notes_by_roster_endpoint={
											endpoints.update_lessons_learned_notes_by_roster_endpoint
										}
										get_prospects_endpoint={endpoints.get_prospects_endpoint}
										scrape_and_update_ffpc_roster_endpoint={
											endpoints.scrape_and_update_ffpc_roster_endpoint
										}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path="/courses"
							element={
								<PrivateRoute>
									<CoursesIndexPage
										get_course_endpoint={endpoints.get_course_endpoint}
										get_courses_endpoint={endpoints.get_courses_endpoint}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path="/courses/:id"
							element={
								<PrivateRoute>
									<CourseProfilePage
										get_course_endpoint={endpoints.get_course_endpoint}
									/>
								</PrivateRoute>
							}
						/>
						{/* Redirect to 404 Not Found Page if no route matches */}
						<Route path="*" element={<h1>404 Not Found</h1>} />
					</Routes>
				</PageContent>
				<Footer create_issue_endpoint={endpoints.create_issue_endpoint} />
			</AuthProvider>
		</Router>
	);
}
