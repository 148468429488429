import React from 'react';
import RosterProfileCard from '../../Roster/RosterProfileCard/RosterProfileCard';

const handleRostersClick = () => {
	window.location.href = `/rosters`;
};

export default function RosterProfilePage({
	get_roster_by_user_endpoint,
	get_player_endpoint,
	get_players_endpoint,
	get_team_endpoint,
	update_roster_by_user_endpoint,
	create_roster_player_by_roster_endpoint,
	delete_roster_player_by_roster_endpoint,
	get_roster_player_note_endpoint,
	create_roster_player_note_endpoint,
	update_roster_player_note_endpoint,
	get_notes_by_roster_endpoint,
	update_notes_by_roster_endpoint,
	get_draft_notes_by_roster_endpoint,
	update_draft_notes_by_roster_endpoint,
	get_lessons_learned_notes_by_roster_endpoint,
	update_lessons_learned_notes_by_roster_endpoint,
	get_prospects_endpoint,
	scrape_and_update_ffpc_roster_endpoint
}) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="relative">
				{/* <div
					className="absolute text-2xl text-gray-700"
					style={{
						top: -46,
						left: 0,
						cursor: 'pointer'
					}}
					onClick={() => handleRostersClick()}
				>
					<span className="hover:underline">My Rosters</span>
					<i
						className="absolute ml-2 fa-solid fa-angle-right"
						style={{
							top: 4,
							left: 120,
							cursor: 'pointer'
						}}
					/>
				</div> */}
				<RosterProfileCard
					get_roster_by_user_endpoint={get_roster_by_user_endpoint}
					get_player_endpoint={get_player_endpoint}
					get_players_endpoint={get_players_endpoint}
					get_team_endpoint={get_team_endpoint}
					update_roster_by_user_endpoint={update_roster_by_user_endpoint}
					create_roster_player_by_roster_endpoint={
						create_roster_player_by_roster_endpoint
					}
					delete_roster_player_by_roster_endpoint={
						delete_roster_player_by_roster_endpoint
					}
					get_roster_player_note_endpoint={get_roster_player_note_endpoint}
					create_roster_player_note_endpoint={
						create_roster_player_note_endpoint
					}
					update_roster_player_note_endpoint={
						update_roster_player_note_endpoint
					}
					get_notes_by_roster_endpoint={get_notes_by_roster_endpoint}
					update_notes_by_roster_endpoint={update_notes_by_roster_endpoint}
					get_draft_notes_by_roster_endpoint={
						get_draft_notes_by_roster_endpoint
					}
					update_draft_notes_by_roster_endpoint={
						update_draft_notes_by_roster_endpoint
					}
					get_lessons_learned_notes_by_roster_endpoint={
						get_lessons_learned_notes_by_roster_endpoint
					}
					update_lessons_learned_notes_by_roster_endpoint={
						update_lessons_learned_notes_by_roster_endpoint
					}
					get_prospects_endpoint={get_prospects_endpoint}
					scrape_and_update_ffpc_roster_endpoint={
						scrape_and_update_ffpc_roster_endpoint
					}
				/>
			</div>
		</div>
	);
}
