import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import PropTypes from 'prop-types';

export default function Article({ get_article_endpoint }) {
	const { id } = useParams();
	const [
		article,
		setArticle
	] = useState(null);
	const [
		error,
		setError
	] = useState(null);

	useEffect(
		() => {
			const fetchArticle = async () => {
				try {
					const response = await fetch(get_article_endpoint.replace('id', id));
					if (response.ok) {
						const result = await response.json();
						setArticle(result.data || null);
					} else {
						const errorData = await response.json();
						setError(
							errorData.message ||
								'An error occurred while fetching the article.'
						);
					}
				} catch (error) {
					setError(
						'An error occurred while fetching the article. Please try again later.'
					);
				}
			};

			fetchArticle();
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		},
		[
			get_article_endpoint,
			id
		]
	);

	if (error) {
		return <div>Error: {error}</div>;
	}

	if (!article) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div className="max-w-screen-md mx-auto px-4 py-8">
			{/* Article Category */}
			{article.category && (
				<span className="text-xs font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded-full inline-block mb-4">
					{article.category}
				</span>
			)}

			{/* Article Title */}
			<h1 className="text-4xl font-bold text-gray-900 mb-3 leading-tight">
				{article.title}
			</h1>

			{/* Article Author */}
			{article.author && (
				<p className="text-sm text-gray-500 pb-6">By {article.author}</p>
			)}

			{/* Article Synopsis */}
			<p className="text-lg italic text-gray-600 mb-6">{article.synopsis}</p>

			{/* Article Body */}
			<div
				className="prose prose-lg text-gray-800 leading-relaxed"
				dangerouslySetInnerHTML={{ __html: article.body }}
			/>
		</div>
	);
}

Article.propTypes = {
	get_article_endpoint: PropTypes.string.isRequired
};
