import { useEffect } from 'react';

const AdBanner = ({ adSlot, adFormat = 'auto', adLayoutKey = '' }) => {
  useEffect(() => {
    // Dynamically load the AdSense script
    const loadAdSense = () => {
      if (window.adsbygoogle) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    };

    loadAdSense();
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-2206327666466632"
        data-ad-slot={adSlot}
        data-ad-format={adFormat}
        data-ad-layout-key={adLayoutKey}
      ></ins>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
    </div>
  );
};

export default AdBanner;
