import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import getPositionCapitalization from '../../../utils/getPositionCapitalization';
import getPlayerTeamCityAbbreviation from '../../../utils/getPlayerTeamCityAbbreviation';
import convertInchesToFeetAndInches from '../../../utils/convertInchesToFeetAndInches';
import PlayerComparisonModal from '../../Player/PlayerComparisonModal/PlayerComparisonModal';
import StatusWidget from '../../StatusWidget/StatusWidget';
import RookieTag from '../../Tags/RookieTag';
import HofTag from '../../Tags/HofTag';
import ContractYearTag from '../../Tags/ContractYearTag';
import Week15OpponentsModal from '../Week15OpponentsModal/Week15OpponentsModal';
import Week16OpponentsModal from '../Week16OpponentsModal/Week16OpponentsModal';
import Week17OpponentsModal from '../Week17OpponentsModal/Week17OpponentsModal';
import PlayerAdpBreakdownModal from '../PlayerAdpBreakdownModal/PlayerAdpBreakdownModal';
import PricingTable from '../../PricingTable/PricingTable';
import { useAuth } from '../../../contexts/AuthContext';

const PlayerPortraitCard = ({
	player,
	getPlayersEndpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) => {
	const { subscription } = useAuth();
	const modalRef = useRef(null);

	const [
		isPlayerCompareModalOpen,
		setisPlayerCompareModalOpen
	] = useState(false);
	const [
		selectedPlayers,
		setSelectedPlayers
	] = useState([
		player
	]);
	const [
		isPlayerWeek15OpponentsModalOpen,
		setIsPlayerWeek15OpponentsModalOpen
	] = useState(false);
	const [
		isPlayerWeek16OpponentsModalOpen,
		setIsPlayerWeek16OpponentsModalOpen
	] = useState(false);
	const [
		isPlayerWeek17OpponentsModalOpen,
		setIsPlayerWeek17OpponentsModalOpen
	] = useState(false);
	const [
		isPricingTableModalOpen,
		setIsPricingTableModalOpen
	] = useState(false);
	const [
		isPlayerAdpBreakdownModalOpen,
		setIsPlayerAdpBreakdownModalOpen
	] = useState(false);

	const handlePlayerComparModalClose = () => {
		setSelectedPlayers([
			player
		]);
		setisPlayerCompareModalOpen(false);
	};

	const handlePlayerSelect = (selectedPlayer) => {
		if (selectedPlayer) {
			// Check if the player is already in the selectedPlayers array
			const isAlreadySelected = selectedPlayers.some(
				(player) => player.id === selectedPlayer.id
			);

			if (!isAlreadySelected) {
				setSelectedPlayers([
					...selectedPlayers,
					selectedPlayer
				]);
			} else {
				console.warn(
					`Player ${selectedPlayer.first_name} ${selectedPlayer.last_name} is already selected.`
				);
			}
		}
	};

	const handlePlayerRemove = (playerId, playerPosition) => {
		setSelectedPlayers((prevPlayers) =>
			prevPlayers.filter(
				(player) =>
					!(player.id === playerId && player.position === playerPosition)
			)
		);
	};

	const handleCompareClick = () => {
		if (player && player.length > 0) {
			setSelectedPlayers([
				player[0]
			]);
		}
		if (
			subscription &&
			Object.keys(subscription).length > 0 &&
			subscription.status === 'active'
		) {
			setisPlayerCompareModalOpen(true);
		} else {
			setIsPricingTableModalOpen(true);
		}
	};

	const handleOpenPlayerWeek15OpponentsModal = () => {
		setIsPlayerWeek15OpponentsModalOpen(true);
	};

	const handleOpenPlayerWeek16OpponentsModal = () => {
		setIsPlayerWeek16OpponentsModalOpen(true);
	};

	const handleOpenPlayerWeek17OpponentsModal = () => {
		setIsPlayerWeek17OpponentsModalOpen(true);
	};

	const handleOpenPlayerAdpBreakdownModal = () => {
		setIsPlayerAdpBreakdownModalOpen(true);
	};

	const closeWeek15OpponentsModal = () => {
		setIsPlayerWeek15OpponentsModalOpen(false);
	};

	const closeWeek16OpponentsModal = () => {
		setIsPlayerWeek16OpponentsModalOpen(false);
	};

	const closeWeek17OpponentsModal = () => {
		setIsPlayerWeek17OpponentsModalOpen(false);
	};

	const closePlayerAdpBreakdownModal = () => {
		setIsPlayerAdpBreakdownModalOpen(false);
	};

	const closePricingTableModal = () => {
		setIsPricingTableModalOpen(false);
	};

	const closeWeek15OpponentsModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerWeek15OpponentsModalOpen(false);
		}
	};

	const closeWeek16OpponentsModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerWeek16OpponentsModalOpen(false);
		}
	};

	const closeWeek17OpponentsModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerWeek17OpponentsModalOpen(false);
		}
	};

	const closePlayerAdpBreakdownModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerAdpBreakdownModalOpen(false);
		}
	};

	const closePricingTableModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPricingTableModalOpen(false);
		}
	};

	// Prevent scrolling when modal is open
	useEffect(
		() => {
			if (isPricingTableModalOpen) {
				document.body.style.overflow = 'hidden';
			}

			return () => {
				// Cleanup on modal close or component unmount
				document.body.style.overflow = '';
			};
		},
		[
			isPricingTableModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek15OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek15OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek15OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek15OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek15OpponentsModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek16OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek16OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek16OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek16OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek16OpponentsModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek17OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek17OpponentsModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerAdpBreakdownModalOpen) {
				document.addEventListener(
					'mousedown',
					closePlayerAdpBreakdownModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closePlayerAdpBreakdownModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePlayerAdpBreakdownModalOnClickOutside
				);
			};
		},
		[
			isPlayerAdpBreakdownModalOpen
		]
	);

	useEffect(
		() => {
			if (isPricingTableModalOpen) {
				document.addEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			};
		},
		[
			isPricingTableModalOpen
		]
	);

	if (player.length === 0 || !player[0].image_url) {
		return null;
	}

	return (
		<div className="md:col-span-6">
			<div className="grid grid-cols-1 border border-gray-300 rounded-xl shadow-lg pt-2 md:pt-14 lg:pt-2 bg-white">
				<div className="px-4 sm:px-6 lg:px-8 flex items-center justify-between mx-2 md:mx-4">
					{/* Player details */}
					<div className="flex items-center flex-col lg:flex-row">
						{/* Player image */}
						<img
							className="h-48 lg:h-56 xl:h-80 rounded mb-4 sm:mb-0 lg:mr-8"
							src={player[0].image_url}
							alt="Player Portrait"
						/>
						<div className="text-center sm:text-left lg:pt-14">
							{/* Player name */}
							<div>
								<div className="flex flex-col lg:flex-row items-center mb-2 space-y-4 lg:space-y-0 lg:space-x-4">
									<h1 className="text-3xl lg:text-4xl font-bold mb-0 mb-0 mt-4 lg:mt-0">
										{player[0].first_name + ' ' + player[0].last_name}
									</h1>
									<div className="flex items-center">
										<StatusWidget
											status={player[0].status}
											description={player[0].injury_description}
										/>
									</div>
									<div>
										<button
											onClick={handleCompareClick}
											className="bg-blue-500 text-white px-4 py-2 rounded ml-0 xl:ml-4 mb-2 lg:mb-0"
										>
											Compare
										</button>
									</div>
								</div>
							</div>
							{/* Player team, jersey number, and position */}
							<div className="flex flex-col lg:flex-row items-center mb-2">
								{/* Team image */}
								{player[0].team &&
								player[0].team.image_url && (
									<Link to={`/teams/${player[0].team_id}`}>
										<img
											className="h-14 sm:mr-2 mb-1 lg:mb-0"
											src={player[0].team.image_url}
											alt="Team Logo"
										/>
									</Link>
								)}
								{/* Team name, jersey number, and position */}
								<div
									className={`ext-lg font text-gray-500 ${player[0].team
										? 'ml-3'
										: ''}`}
								>
									{player[0].team && (
										<Link
											to={`/teams/${player[0].team.id}`}
											className="text-blue-500 hover:underline"
										>
											{player[0].team.name}
										</Link>
									)}
									{player[0].jersey_number !== null &&
										` • #${player[0].jersey_number}`}
									<span className="hidden md:contents">
										{player[0].position &&
											` • ${getPositionCapitalization(player[0].position)}`}
									</span>
									<span className="contents md:hidden">
										{player[0].position &&
											` • ${getPositionAbbreviation(player[0].position)}`}
									</span>
								</div>
							</div>
							<hr className="mt-4 block lg:hidden" />

							{/* Additional attributes */}
							<div className="flex flex-wrap items-start text-base text-gray-500 mt-6 md:mt-2 pb-4 md:pb-8">
								<div className="flex items-center mr-6 mb-2">
									{player[0].height &&
									player[0].weight && (
										<div>
											<span className="font-bold uppercase">HT/WT</span>
											<span className="ml-2">
												{convertInchesToFeetAndInches(player[0].height)},{' '}
												{player[0].weight} lbs
											</span>
										</div>
									)}
								</div>

								<div className="flex items-center mr-6 mb-2">
									{player[0].age && (
										<div>
											<span className="font-bold uppercase">AGE</span>
											<span className="ml-2">{player[0].age}</span>
										</div>
									)}
								</div>

								{player[0].college && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">COLLEGE</span>
										<span className="ml-2">{player[0].college}</span>
									</div>
								)}

								{!player[0].is_college_player && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">DRAFT PICK</span>
										<span className="ml-2">
											{player[0].draft_pick ? (
												`${player[0].draft_pick === '1.1'
													? '1.10'
													: player[0].draft_pick} ${player[0].draft_year
													? `(${player[0].draft_year})`
													: ''}`
											) : (
												'Undrafted'
											)}
										</span>
									</div>
								)}

								{player[0].draft_year &&
								player[0].draft_year < 2025 && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">EXP</span>
										<span className="ml-2 flex items-center">
											{`${new Date().getFullYear() - player[0].draft_year} yrs`}
											{(new Date().getFullYear() - player[0].draft_year === 0 ||
												player[0].is_rookie) && <RookieTag />}
											{player[0].is_hof && <HofTag />}
											{player[0].is_contract_year && <ContractYearTag />}
										</span>
									</div>
								)}
								{player[0].contract_details && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">CONTRACT</span>
										<Link
											to={`${player[0].contract_url}`}
											target="_blank"
											rel="noopener noreferrer"
											className="text-blue-500 hover:underline"
										>
											{' '}
											<span className="ml-2 text-blue-500 hover:underline">
												{`${player[0].contract_details} (${player[0]
													.contract_end_year} ${player[0].id === 59
													? 'RFA'
													: 'UFA'})`}
												<span className="hidden md:contents">
													<i className="fa-solid fa-arrow-up-right-from-square ml-2" />
												</span>
											</span>
										</Link>
									</div>
								)}
								{player[0] &&
								player[0].underdog_adp && (
									<div
										className="flex items-center mr-6 mb-2"
										onClick={() => handleOpenPlayerAdpBreakdownModal(player[0])}
									>
										<span className="font-bold uppercase">ADP</span>
										<span className="text-blue-500 ml-2 cursor-pointer hover:underline">
											{player[0].underdog_adp}
										</span>
									</div>
								)}
								{player[0] &&
								player[0].week_17_opponent &&
								player[0].week_17_opponent.opponent_team &&
								player[0].week_17_opponent.opponent_team.name && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">
											WK 15, 16, 17 OPP
										</span>
										<span
											className="text-blue-500 ml-2 cursor-pointer hover:underline"
											onClick={() => handleOpenPlayerWeek15OpponentsModal()}
										>
											{getPlayerTeamCityAbbreviation(
												player[0].week_15_opponent.opponent_team.location,
												player[0].week_15_opponent.opponent_team.name
											)}
										</span>,
										<span
											className="text-blue-500 ml-2 cursor-pointer hover:underline"
											onClick={() => handleOpenPlayerWeek16OpponentsModal()}
										>
											{getPlayerTeamCityAbbreviation(
												player[0].week_16_opponent.opponent_team.location,
												player[0].week_16_opponent.opponent_team.name
											)}
										</span>,
										<span
											className="text-blue-500 ml-2 cursor-pointer hover:underline"
											onClick={() => handleOpenPlayerWeek17OpponentsModal()}
										>
											{getPlayerTeamCityAbbreviation(
												player[0].week_17_opponent.opponent_team.location,
												player[0].week_17_opponent.opponent_team.name
											)}
										</span>
									</div>
								)}
								{player[0] &&
								player[0].team &&
								player[0].team.bye_week && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">BYE WEEK</span>
										<span className="ml-2">{player[0].team.bye_week}</span>
									</div>
								)}
								{(player[0].twitter ||
									player[0].instagram ||
									player[0].facebook) && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">SOCIAL</span>
										{player[0].twitter && (
											<Link
												to={player[0].twitter}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-twitter ml-2" />
											</Link>
										)}
										{player[0].instagram && (
											<Link
												to={player[0].instagram}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-instagram ml-2" />
											</Link>
										)}
										{player[0].facebook && (
											<Link
												to={player[0].facebook}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-facebook ml-2" />
											</Link>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				{/* Player Comparison Modal */}
				<PlayerComparisonModal
					getPlayersEndpoint={getPlayersEndpoint}
					isPlayerCompareModalOpen={isPlayerCompareModalOpen}
					handlePlayerComparModalClose={handlePlayerComparModalClose}
					handlePlayerSelect={handlePlayerSelect}
					handlePlayerRemove={handlePlayerRemove}
					selectedPlayers={selectedPlayers}
				/>

				{/* Week 15 Opponents Modal */}
				{isPlayerWeek15OpponentsModalOpen && (
					<Week15OpponentsModal
						player={player[0]}
						isPlayerWeek15OpponentsModalOpen={isPlayerWeek15OpponentsModalOpen}
						closeWeek15OpponentsModal={closeWeek15OpponentsModal}
						modalRef={modalRef}
					/>
				)}

				{/* Week 16 Opponents Modal */}
				{isPlayerWeek16OpponentsModalOpen && (
					<Week16OpponentsModal
						player={player[0]}
						isPlayerWeek16OpponentsModalOpen={isPlayerWeek16OpponentsModalOpen}
						closeWeek16OpponentsModal={closeWeek16OpponentsModal}
						modalRef={modalRef}
					/>
				)}

				{/* Week 17 Opponents Modal */}
				{isPlayerWeek17OpponentsModalOpen && (
					<Week17OpponentsModal
						player={player[0]}
						isPlayerWeek17OpponentsModalOpen={isPlayerWeek17OpponentsModalOpen}
						closeWeek17OpponentsModal={closeWeek17OpponentsModal}
						modalRef={modalRef}
					/>
				)}

				{/* Player ADP Breakdown Modal */}
				{isPlayerAdpBreakdownModalOpen && (
					<PlayerAdpBreakdownModal
						player={player[0]}
						isPlayerAdpBreakdownModalOpen={isPlayerAdpBreakdownModalOpen}
						closePlayerAdpBreakdownModal={closePlayerAdpBreakdownModal}
						modalRef={modalRef}
					/>
				)}

				{/* Pricing Table Modal */}
				{isPricingTableModalOpen && (
					<div
						className="fixed inset-0 flex justify-center items-center"
						style={{
							zIndex: 1000,
							backgroundColor: 'rgba(0, 0, 0, 0.5)'
						}}
					>
						<div
							ref={modalRef}
							className="relative bg-white rounded-lg p-6 shadow-lg"
							style={{ width: 'auto', maxHeight: '90%', overflowY: 'auto' }}
						>
							<button
								className="absolute top-0 right-0 p-4"
								onClick={closePricingTableModal}
							>
								<svg
									className="h-6 w-6 text-gray-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
							<h2 className="text-2xl font-bold mb-4">Our Pricing</h2>
							<div className="overflow-x-auto">
								<div className="flex flex-col">
									<PricingTable
										create_checkout_session_endpoint={
											create_checkout_session_endpoint
										}
										stripe_public_key={stripe_public_key}
										stripe_product_essential_id={stripe_product_essential_id}
										stripe_product_advanced_id={stripe_product_advanced_id}
										stripe_product_ultimate_id={stripe_product_ultimate_id}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PlayerPortraitCard;
