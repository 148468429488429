import React, { useRef } from 'react';

const RosterFormModal = ({
	isRosterProfileUpdate,
	isOpen,
	closeModal,
	editingRoster,
	rosterTeamName,
	setRosterTeamName,
	rosterLeagueName,
	setRosterLeagueName,
	rosterPlatform,
	setRosterPlatform,
	rosterUrl,
	setRosterUrl,
	rosterFormat,
	setRosterFormat,
	rosterScoring,
	setRosterScoring,
	additionalEmails,
	handleEmailChange,
	handleAddEmail,
	handleRemoveEmail,
	handleSaveRoster,
	isDraftModeEnabled
}) => {
	const modalRef = useRef(null);

	if (!isOpen) return null;

	return (
		<div
			className="fixed inset-0 z-50 overflow-auto flex w-full"
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
		>
			<div
				className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
				ref={modalRef}
			>
				<button className="absolute top-0 right-0 p-4" onClick={closeModal}>
					<svg
						className="h-6 w-6 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				<h2 className="text-xl font-bold mb-4">
					{editingRoster ? 'Edit Roster' : 'Create Roster'}
				</h2>
				<form>
					<label className="block mb-2">
						Team Name
						<input
							type="text"
							className="block w-full p-2 border border-gray-300 rounded mt-1"
							value={rosterTeamName}
							onChange={(e) => setRosterTeamName(e.target.value)}
							required
						/>
					</label>
					<label className="block mb-2">
						League Name
						<input
							type="text"
							className="block w-full p-2 border border-gray-300 rounded mt-1"
							value={rosterLeagueName}
							onChange={(e) => setRosterLeagueName(e.target.value)}
							required
						/>
					</label>
					<label className="block mb-2">
						Platform
						<select
							value={rosterPlatform}
							onChange={(e) => setRosterPlatform(e.target.value)}
							className="mt-1 p-2 border rounded w-full"
						>
							<option value="">Select Platform...</option>
							<option value="Yahoo">Yahoo</option>
							<option value="FFPC">FFPC</option>
							<option value="Sleeper">Sleeper</option>
							<option value="Underdog">Underdog</option>
							<option value="ESPN">ESPN</option>
							<option value="NFL">NFL</option>
						</select>
					</label>
					<label className="block mb-2">
						Roster Url
						<input
							type="text"
							className="block w-full p-2 border border-gray-300 rounded mt-1"
							value={rosterUrl}
							onChange={(e) => setRosterUrl(e.target.value)}
							placeholder="Enter the URL to your roster"
						/>
					</label>
					<label className="block mb-2">
						Format
						<select
							value={rosterFormat}
							onChange={(e) => setRosterFormat(e.target.value)}
							className="mt-1 p-2 border rounded w-full"
						>
							<option value="">Select Format...</option>
							<option value="Best Ball">Best Ball</option>
							<option value="Redraft">Redraft</option>
							<option value="Dynasty">Dynasty</option>
						</select>
					</label>
					<label className="block mb-2">
						Scoring
						<select
							value={rosterScoring}
							onChange={(e) => setRosterScoring(e.target.value)}
							className="mt-1 p-2 border rounded w-full"
						>
							<option value="">Select Scoring...</option>
							<option value="Half PPR">Half PPR</option>
							<option value="Half PPR - Superflex">Half PPR - Superflex</option>
							<option value="PPR">PPR</option>
							<option value="PPR - TE Premium">PPR - TE Premium</option>
							<option value="PPR - Superflex">PPR - Superflex</option>
							<option value="PPR - Superflex - TE Premium">
								PPR - Superflex - TE Premium
							</option>
							<option value="Standard">Standard</option>
							<option value="Standard - Superflex">Standard - Superflex</option>
						</select>
					</label>
					<label className="block mb-2">
						{additionalEmails.length > 1 ? 'Emails' : 'Email'}
						{additionalEmails.map((email, index) => (
							<div key={index} className="flex items-center mb-2">
								<input
									type="email"
									className="block w-full p-2 border border-gray-300 rounded mt-1"
									value={email}
									onChange={(e) => handleEmailChange(index, e.target.value)}
									placeholder="Enter user email"
								/>
								{index > 0 && (
									<button
										type="button"
										onClick={() => handleRemoveEmail(index)}
										className="ml-2 text-red-500 hover:underline"
									>
										Remove
									</button>
								)}
							</div>
						))}
						<button
							type="button"
							onClick={handleAddEmail}
							className="mt-2 text-blue-500 hover:underline"
						>
							Add another email
						</button>
					</label>
					<div className="flex justify-end">
						<div className="flex justify-between mt-4">
							<button
								type="button"
								className="mx-2 px-4 py-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="mx-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
								onClick={() => handleSaveRoster(false)}
							>
								Save
							</button>
							{!isRosterProfileUpdate &&
							!isDraftModeEnabled && (
								<button
									type="button"
									className="mx-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
									onClick={() => handleSaveRoster(true)}
								>
									Save and add players
								</button>
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default RosterFormModal;
