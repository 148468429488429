import React from 'react';
import HelpCenterIndexCard from '../../HelpCenter/HelpCenterIndexCard/HelpCenterIndexCard';

export default function HelpCenterIndexPage({ get_articles_endpoint }) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<div className="grid grid-cols-1">
					<HelpCenterIndexCard get_articles_endpoint={get_articles_endpoint} />
				</div>
			</div>
		</div>
	);
}
