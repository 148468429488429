import React, { useState, useEffect, useRef } from 'react';
import FilterBySearchInput from '../../Filters/FilterBySearchInput';
import PlayerSearchBarResultsList from '../PlayerSearchBar/PlayerSearchBarResultsList';

const PlayerSearchBar = ({
	getPlayersEndpoint,
	handlePlayerSelect,
	handleAddPlayer,
	isRosterBuilder,
	isVisibleByDefault,
	isCompareModal,
	isDarkMode,
	allSectionsCollapsed
}) => {
	const [
		search,
		setSearch
	] = useState('');
	const [
		players,
		setPlayers
	] = useState([]);
	const [
		isSearchInputVisible,
		setIsSearchInputVisible
	] = useState(isVisibleByDefault || false);
	const [
		isSearchBarResultsList,
		setIsSearchBarResultsList
	] = useState(true);
	const searchInputRef = useRef(null);
	const searchBarContainerRef = useRef(null);

	const fetchPlayers = () => {
		let url = `${getPlayersEndpoint}?search=${search}`;
		fetch(url)
			.then((response) => response.json())
			.then((result) => {
				setPlayers(result.players || []);
			})
			.catch((error) => {
				console.error('Error fetching players:', error);
			});
	};

	useEffect(
		() => {
			setPlayers([]);
			if (search) {
				fetchPlayers();
			}
		},
		[
			search,
			getPlayersEndpoint
		]
	);

	// Passed from RosterProfileCard to hide search bar input field when all sections are collapsed
	useEffect(
		() => {
			if (allSectionsCollapsed) {
				setIsSearchInputVisible(false);
			} else if (allSectionsCollapsed === false) {
				setIsSearchInputVisible(true);
			}
		},
		[
			allSectionsCollapsed
		]
	);

	const handleSearchInputChange = (event) => {
		setIsSearchBarResultsList(true);
		setSearch(event.target.value);
	};

	const handlePlayerClick = (player) => {
		if (isCompareModal === true) {
			handlePlayerSelect(player);
			setSearch('');
			setIsSearchBarResultsList(false);
		} else if (isRosterBuilder === true) {
			handleAddPlayer(player.position, player.id);
			setSearch('');
			setIsSearchBarResultsList(false);
		} else {
			window.location.href = `/players/${player.id}?position=${player.position}`;
		}
		setIsSearchBarResultsList(false);
		setSearch('');

		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				searchBarContainerRef.current &&
				!searchBarContainerRef.current.contains(event.target)
			) {
				setIsSearchBarResultsList(false);
				setSearch('');
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="flex items-center relative" ref={searchBarContainerRef}>
			{/* Magnifying glass icon */}
			<i
				className={`fa-solid fa-magnifying-glass cursor-pointer ${isDarkMode
					? 'text-gray-500'
					: 'text-white'} text-2xl py-5 pr-2.5`}
				onClick={() => setIsSearchInputVisible(!isSearchInputVisible)}
			/>

			{/* Search input and results */}
			<div
				className={`ml-2 relative ${isSearchInputVisible
					? 'contents'
					: 'hidden'}`}
			>
				<FilterBySearchInput
					ref={searchInputRef}
					value={search}
					placeholder="Search for a player"
					onChange={handleSearchInputChange}
					onEnter={fetchPlayers}
					isDarkMode={isDarkMode}
				/>
				{players.length > 0 &&
				isSearchBarResultsList && (
					<PlayerSearchBarResultsList
						players={players}
						handlePlayerClick={handlePlayerClick}
					/>
				)}
			</div>
		</div>
	);
};

export default PlayerSearchBar;
