import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import TeamComparisonModal from '../../Team/TeamComparisonModal/TeamComparisonModal';
import PricingTable from '../../PricingTable/PricingTable';
import { useAuth } from '../../../contexts/AuthContext';

function TeamPortraitCard({
	team,
	get_teams_endpoint,
	temperature,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	const { subscription } = useAuth();
	const modalRef = useRef(null);
	const [
		isTeamCompareModalOpen,
		setisTeamCompareModalOpen
	] = useState(false);
	const [
		selectedTeams,
		setSelectedTeams
	] = useState([]);
	const [
		isPricingTableModalOpen,
		setIsPricingTableModalOpen
	] = useState(false);

	// Effect to handle the initial null team and set `selectedTeams` when `team` is defined
	useEffect(
		() => {
			if (team) {
				setSelectedTeams([
					team
				]);
			}
		},
		[
			team
		]
	);

	const handleTeamComparModalClose = () => {
		setSelectedTeams([
			team
		]);
		setisTeamCompareModalOpen(false);
	};

	const handleTeamSelect = (selectedTeam) => {
		if (selectedTeam) {
			// Check if the team is already in the selectedTeams array
			const isAlreadySelected = selectedTeams.some(
				(team) => team.id === selectedTeam.id
			);

			if (!isAlreadySelected) {
				setSelectedTeams([
					...selectedTeams,
					selectedTeam
				]);
			} else {
				console.warn(`Team ${selectedTeam.name} is already selected.`);
			}
		}
	};

	const handleTeamRemove = (teamId) => {
		setSelectedTeams((prevTeams) =>
			prevTeams.filter((team) => !(team.id === teamId))
		);
	};

	const handleCompareClick = () => {
		if (team && team.length > 0) {
			setSelectedTeams([
				team[0]
			]);
		}
		if (
			subscription &&
			Object.keys(subscription).length > 0 &&
			subscription.status === 'active'
		) {
			setisTeamCompareModalOpen(true);
		} else {
			setIsPricingTableModalOpen(true);
		}
	};

	const closePricingTableModal = () => {
		setIsPricingTableModalOpen(false);
	};

	const closePricingTableModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPricingTableModalOpen(false);
		}
	};

	// Prevent scrolling when modal is open
	useEffect(
		() => {
			if (isPricingTableModalOpen) {
				document.body.style.overflow = 'hidden';
			}

			return () => {
				// Cleanup on modal close or component unmount
				document.body.style.overflow = '';
			};
		},
		[
			isPricingTableModalOpen
		]
	);

	useEffect(
		() => {
			if (isPricingTableModalOpen) {
				document.addEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			};
		},
		[
			isPricingTableModalOpen
		]
	);

	return (
		<div className="md:col-span-6">
			<div className="grid grid-cols-1 border border-gray-300 rounded-xl shadow-lg pt-2 md:pt-14 lg:pt-2 bg-white">
				<div className="px-4 sm:px-6 lg:px-8 flex items-center justify-between mx-4">
					{/* Team details */}
					<div className="flex items-center flex-col lg:flex-row">
						{/* Team image */}
						<img
							className="h-48 lg:h-56 xl:h-80 rounded mb-4 sm:mb-0 lg:mr-8"
							src={team.image_url}
							alt="Team Logo"
						/>
						<div className="text-center lg:text-left lg:pt-14">
							{/* Team name */}
							<div className="flex items-center justify-center lg:justify-start">
								{/* Team name */}
								<h1 className="text-3xl lg:text-4xl font-bold">{team.name}</h1>
								<div className="hidden lg:block">
									{/* Compare button */}
									<button
										onClick={handleCompareClick}
										className="bg-blue-500 text-white px-4 py-2 rounded md:ml-8"
									>
										Compare
									</button>
								</div>
							</div>
							{/* Team location and temperature */}
							<div className="items-center sm:items-start mt-1 mb-4 lg:mb-2">
								<div className="text-center lg:text-left">
									<p className="text-lg font text-gray-500">
										{team.location}
										{temperature !== null &&
											` • ${temperature.temp}\u00B0, ${temperature.weather}`}
									</p>
								</div>
							</div>
							<div className="flex justify-center lg:justify-start">
								<button
									onClick={handleCompareClick}
									className="bg-blue-500 text-white px-4 py-2 rounded mb-2 lg:mb-0 md:ml-4 lg:hidden"
								>
									Compare
								</button>
							</div>

							{/* Additional attributes */}
							<div className="flex flex-wrap items-start text-base text-gray-500 mt-3 pb-8">
								{team.conference && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">CONFERENCE</span>
										<span className="ml-2">{team.conference}</span>
									</div>
								)}
								{team.division && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">DIVISION</span>
										<span className="ml-2">{team.division}</span>
									</div>
								)}
								{team.year_established && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">ESTABLISHED</span>
										<span className="ml-2">{team.year_established}</span>
										{/* Change "1970" to the actual established year */}
									</div>
								)}
								{team.championships !== null &&
								team.championships >= 0 && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">CHAMPIONSHIPS</span>
										<span className="ml-2">
											{team.championships === 0 ? 0 : team.championships}
										</span>
									</div>
								)}
								{team.website && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">WEBSITE</span>
										<Link
											to={`https://${team.website}`}
											className="text-blue-500 ml-2 cursor-pointer hover:underline"
											target="_blank"
											rel="noopener noreferrer"
										>
											{team.website}
										</Link>
									</div>
								)}
								{team.twitter || team.instagram || team.facebook ? (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">SOCIAL</span>
										{team.twitter && (
											<Link
												to={team.twitter}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-twitter ml-2 mr-1.5" />
											</Link>
										)}
										{team.instagram && (
											<Link
												to={team.instagram}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-instagram mr-1.5" />
											</Link>
										)}
										{team.facebook && (
											<Link
												to={team.facebook}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-facebook" />
											</Link>
										)}
									</div>
								) : (
									''
								)}
								{team.w_l_odds && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">W/L ODDS</span>
										<Link
											to={
												team.w_l_odds_affiliate_website_url ? (
													team.w_l_odds_affiliate_website_url
												) : (
													''
												)
											}
											className="flex items-center"
											target="_blank"
											rel="noopener noreferrer"
										>
											<span className="ml-2 hover:underline">
												O/U {team.w_l_odds}
											</span>
											{team.w_l_odds_affiliate_image_url && (
												<img
													src={team.w_l_odds_affiliate_image_url}
													alt="Affiliate Logo"
													className="ml-2"
													style={{ height: 25 }}
												/>
											)}
										</Link>
									</div>
								)}
								{team.next_game && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">NEXT GAME</span>
										<span className="ml-2">
											<Link to={`/teams/${team.next_game_id}`}>
												{team.next_game}
											</Link>
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>

				{/* Player Comparison Modal */}
				<TeamComparisonModal
					get_teams_endpoint={get_teams_endpoint}
					isTeamCompareModalOpen={isTeamCompareModalOpen}
					handleTeamComparModalClose={handleTeamComparModalClose}
					handleTeamSelect={handleTeamSelect}
					handleTeamRemove={handleTeamRemove}
					selectedTeams={selectedTeams}
				/>

				{/* Pricing Table Modal */}
				{isPricingTableModalOpen && (
					<div
						className="fixed inset-0 flex justify-center items-center"
						style={{
							zIndex: 1000,
							backgroundColor: 'rgba(0, 0, 0, 0.5)'
						}}
					>
						<div
							ref={modalRef}
							className="relative bg-white rounded-lg p-6 shadow-lg"
							style={{ width: 'auto', maxHeight: '90%', overflowY: 'auto' }}
						>
							<button
								className="absolute top-0 right-0 p-4"
								onClick={closePricingTableModal}
							>
								<svg
									className="h-6 w-6 text-gray-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
							<h2 className="text-2xl font-bold mb-4">Our Pricing</h2>
							<div className="overflow-x-auto">
								<div className="flex flex-col">
									<PricingTable
										create_checkout_session_endpoint={
											create_checkout_session_endpoint
										}
										stripe_public_key={stripe_public_key}
										stripe_product_essential_id={stripe_product_essential_id}
										stripe_product_advanced_id={stripe_product_advanced_id}
										stripe_product_ultimate_id={stripe_product_ultimate_id}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default TeamPortraitCard;
