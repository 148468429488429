import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IssueForm from '../Issue/IssueForm';

export default function Footer({ create_issue_endpoint }) {
	const [
		isCreateIssueModalOpen,
		setIsCreateIssueModalOpen
	] = useState(false);

	const handleCreateIssueModalOpen = () => {
		setIsCreateIssueModalOpen(true);
	};

	const handleCreateIssueModalClose = () => {
		setIsCreateIssueModalOpen(false);
	};

	return (
		<React.Fragment>
			<footer className="bg-gray-100 mt-16">
				<hr />
				<div className="container p-6 pb-2 md:pt-10 md:px-20">
					<div className="grid grid-cols-1 md:grid-cols-3 gap-10">
						<div className="mt-6 md:mt-0">
							<span className="font-semibold">Support</span>
							<ol className="mt-4">
								<Link to="/help-center">
									<li className="my-4 hover:underline">Help Center</li>
								</Link>
								<li
									className="my-4 cursor-pointer"
									onClick={handleCreateIssueModalOpen}
								>
									Report an issue
								</li>
								{/* <li className="my-4">Visit our blog</li> */}
							</ol>
						</div>
						<div className="mt-6 md:mt-0">
							<span className="font-semibold">Community</span>
							<ol className="mt-4">
								<li className="my-4">Combating homelessness</li>
							</ol>
						</div>
						<div className="mt-6 md:mt-0">
							<span className="font-semibold">Fantasy Fitness</span>
							<ol className="mt-4">
								<li className="my-4">Learn about new features</li>
								<li className="my-4">A word from our founders</li>
								<li className="my-4">Our roadmap</li>
							</ol>
						</div>
					</div>
					<div className="block mt-6">
						<hr className="mb-4" />
						<p className="py-2 text-start text-sm md:text-base text-gray-700">
							Fantasy Fitness™ is a trademark of Fantasy Fitness, LLC. All
							rights reserved.
						</p>
						<p className="py-2 text-start text-sm md:text-base text-gray-700">
							© {new Date().getFullYear()} Fantasy Fitness, LLC
						</p>
					</div>
				</div>

				{/* Report an issue modal */}
				{isCreateIssueModalOpen && (
					<div
						className="fixed inset-0 flex justify-center items-center"
						style={{
							zIndex: 1000,
							backgroundColor: 'rgba(0, 0, 0, 0.5)'
						}}
					>
						<div
							className="relative bg-white rounded-lg p-8 shadow-xl"
							style={{
								maxHeight: '90vh',
								overflowY: 'auto'
							}}
						>
							<button
								className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200 transition duration-300"
								onClick={handleCreateIssueModalClose}
							>
								<svg
									className="h-6 w-6 text-gray-600"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
							<IssueForm
								endpoint={create_issue_endpoint}
								handleCreateIssueModalClose={handleCreateIssueModalClose}
							/>
						</div>
					</div>
				)}

				{/* Report an issue success modal */}
				{/*  <div
					className="fixed inset-0 flex justify-center items-center"
					style={{
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						display: 'none'
					}}
				>
					<div
						className="relative bg-white rounded-lg p-8 shadow-xl"
						style={{
							maxHeight: '90vh',
							overflowY: 'auto'
						}}
					>
						<button
							className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200 transition duration-300"
							onClick={handleCreateIssueModalClose}
						>
							<svg
								className="h-6 w-6 text-gray-600"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<div className="text-center">
							<svg
								className="h-12 w-12 text-green-500 mx-auto"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M5 13l4 4L19 7"
								/>
							</svg>
							<p className="mt-4 text-lg text-gray-700">
								Thank you for reporting the issue. We will look into it shortly.
							</p>
						</div>
					</div>
				</div>
				*/}
			</footer>
		</React.Fragment>
	);
}
